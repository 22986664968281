<template>
  <div>
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%" src="../../assets/logo.png" alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 17px">
          <div style="font-size: 18px">光合城晔光小站</div>
          <div>住户信息登记</div>
        </div>
      </div>
      <div class="inputFrom" v-if="verifyshow">
        <div>所住小区</div>
        <div>
          <input v-model="idList.RIdTitle" type="text" @click="idListRId = true"
            readonly="readonly" placeholder="请选择您所住小区" />
          <van-popup
            v-model="idListRId"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId"
            >
            </van-picker>
          </van-popup>
        </div>
        <div>楼栋</div>
        <div>
          <input v-model="idList.BIdTitle" type="text" @click="idListBId = true"
            readonly="readonly" placeholder="请选择您所住楼栋" />
          <van-popup
            v-model="idListBId"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="BIdlist"
              value-key="Title"
              @cancel="idListBId = false"
              @confirm="onidListBId"
            >
            </van-picker>
          </van-popup>
        </div>
        <div>单元</div>
        <div>
           <input v-model="idList.BUIdTitle" type="text" @click="idListBUId = true"
            readonly="readonly" placeholder="请选择您所住单元" />
          <van-popup
            v-model="idListBUId"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="BUIdlist"
              value-key="Title"
              @cancel="idListBUId = false"
              @confirm="onidListBUId"
            >
            </van-picker>
          </van-popup>
        </div>
        <div>楼层</div>
        <div>
          <input v-model="idList.BFIdTitle" type="text" @click="idListBFId = true"
            readonly="readonly" placeholder="请选择您所住楼层" />
          <van-popup
            v-model="idListBFId"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="BFIdlist"
              value-key="FloorNo"
              @cancel="idListBFId = false"
              @confirm="onidListBFId"
            >
            </van-picker>
          </van-popup>
        </div>
        <div>房号</div>
        <div>
           <input v-model="idList.BRIdTitle" type="text" @click="idListBRId = true"
            readonly="readonly" placeholder="请选择您所住房号" />
          <van-popup
            v-model="idListBRId"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="BRIdlist"
              value-key="RoomNO"
              @cancel="idListBRId = false"
              @confirm="onidListBRId"
            >
            </van-picker>
          </van-popup>
        </div>
        <div>姓名</div>
        <div>
          <input type="text" placeholder="请输入您的姓名" />
        </div>
      </div>
      <div class="fromButton" v-if="verifyshow">
        <van-button type="info" @click="dengji">登记家庭成员信息</van-button>
      </div>
      <div v-if="addshow">
        <van-cell-group>
          <van-field v-model="datafrom.Name" label="姓名" placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.sexName" @click="sexshow = true"
            readonly="readonly" label="性别" placeholder="请选择性别" />
          <van-popup
            v-model="sexshow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="sexlist"
              value-key="Name"
              @cancel="sexshow = false"
              @confirm="onsexshow"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
           <van-field v-model="datafrom.NationName" @click="Nationshow = true"
            readonly="readonly" label="民族" placeholder="请选择民族" />
          <van-popup
            v-model="Nationshow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="minzu"
              value-key="Name"
              @cancel="Nationshow = false"
              @confirm="onNationshow"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="value" label="身份证号" placeholder="请输入身份证号码" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.HouserStatusName" @click="HouserStatus = true"
            readonly="readonly" label="户口状态" placeholder="请选择户口状态" />
          <van-popup
            v-model="HouserStatus"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="zhuangtai"
              value-key="Name"
              @cancel="HouserStatus = false"
              @confirm="onHouserStatus"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.RegisterKindName" @click="RegisterKind = true"
            readonly="readonly" label="户口关系" placeholder="请选择户口关系" />
          <van-popup
            v-model="RegisterKind"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="guanxi"
              value-key="Name"
              @cancel="RegisterKind = false"
              @confirm="onRegisterKind"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.LiveCityName" @click="LiveCity = true" readonly="readonly" label="户籍地址" placeholder="请选择户籍地址" />
           <van-popup
            v-model="LiveCity"
            position="bottom"
            get-container="body"
          >
            <van-cascader
              title="请选择现住址"
              :options="NativeCantonCodedata"
              @close="LiveCity = false"
              :field-names="{
                text: 'text',
                value: 'value',
                children: 'children',
              }"
              @finish="onLiveCity"
            />
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="value" label="联系电话" placeholder="请输入联系电话" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.MarriageName" @click="Marriage = true"
            readonly="readonly" label="婚姻状态" placeholder="请选择婚姻状态" />
          <van-popup
            v-model="Marriage"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="hunyin"
              value-key="Name"
              @cancel="Marriage = false"
              @confirm="onMarriage"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
           <van-field v-model="datafrom.EducationName" @click="Education = true"
            readonly="readonly" label="文化程度" placeholder="请选择学历" />
          <van-popup
            v-model="Education"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="xveli"
              value-key="Name"
              @cancel="Education = false"
              @confirm="onEducation"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="value" label="工作单位" placeholder="请输入现工作单位" />
        </van-cell-group>
      </div>
      <div class="buttons" v-if="addshow">
        <van-button style="border: 1px solid #e6e6e6;" type="default">返回</van-button>
        <van-button type="primary">提交并录入下一条</van-button>
        <van-button type="info">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  WeGetRegionList,
  WeGetBuildList,
  WeGetBuildUnitList,
  WeGetBuildFloorList,
  WeGetBuildRoomList,
  GetCodeEntrysList,
} from "@/api/yeguang";
export default {
  data() {
    return {
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      diqu:[],
      guanxi:[],
      minzu:[],
      xveli:[],
      zhuangtai:[],
      hunyin:[],
      NativeCantonCodedata: [],
      sexlist:[
        {id:'1',Name:'男'},
        {id:'2',Name:'女'},
      ],
      idList: {
        organCode: "4103110201",
        RId: undefined,
        BId: undefined,
        BUId: undefined,
        BFId: undefined,
        BRId: undefined,
      },
      datafrom:{
        Name:"",
        sex:"",
      },
      idListRId: false,
      idListBId: false,
      idListBUId: false,
      idListBFId: false,
      idListBRId: false,
      addshow: false,
      verifyshow: true,
      sexshow:false,
      Nationshow:false,
      HouserStatus:false,
      RegisterKind:false,
      Marriage:false,
      Education:false,
      LiveCity:false,
    };
  },
  created() {
    this.RegionList();
  },
  methods: {
    // 小区列表
    RegionList: function () {
      WeGetRegionList({ organCode: "4103110201" }).then((res) => {
        this.ridlist = res.data.data;
      });
      GetCodeEntrysList({kind:14}).then((res)=>{ //户主关系
        this.guanxi = res.data.data;
      });
      GetCodeEntrysList({kind:3}).then((res)=>{ //民族
        this.minzu = res.data.data;
      });
      GetCodeEntrysList({kind:5}).then((res)=>{ //学历
        this.xveli = res.data.data;
      });
      GetCodeEntrysList({kind:13}).then((res)=>{ //户口状态
        this.zhuangtai = res.data.data;
      });
      GetCodeEntrysList({kind:4}).then((res)=>{ //婚姻
        this.hunyin = res.data.data;
      });
      // 地区
      GetCodeEntrysList({ kind: "2" }).then((res) => {
        var Citys = [],
          Citysx = [],
          Citysq = [],
          Citysqp = [],
          list = res.data.data;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Coding.length == "2") {
            Citys.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "4") {
            Citysx.push({
              value: list[i].Coding,
              children: [],
              text: list[i].Alias,
            });
          } else if (list[i].Coding.length == "6") {
            if (list[i].Coding == "410324") {
              Citysq.push({
                value: list[i].Coding,
                text: list[i].Alias,
                children: [],
              });
            } else {
              Citysq.push({ value: list[i].Coding, text: list[i].Alias });
            }
          } else if (list[i].Coding.length > "6") {
            if (list[i].Coding.substr(0, 6) == "410324") {
              Citysqp.push({ value: list[i].Coding, text: list[i].Alias });
            }
          }
        }
        for (var p = 0; p < Citysq.length; p++) {
          if (Citysq[p].value.substr(0, 6) == "410324") {
            Citysq[p].children = Citysqp;
          }
        }
        for (var i = 0; i < Citysq.length; i++) {
          for (var j = 0; j < Citysx.length; j++) {
            if (Citysq[i].value.substr(0, 4) == Citysx[j].value) {
              Citysx[j].children.push(Citysq[i]);
            }
          }
        }
        for (var z = 0; z < Citysx.length; z++) {
          for (var y = 0; y < Citys.length; y++) {
            if (Citysx[z].value.substr(0, 2) == Citys[y].value) {
              Citys[y].children.push(Citysx[z]);
            }
          }
        }
        this.NativeCantonCodedata = Citys;
        console.log(this.NativeCantonCodedata);
        this.NativeCantonCodedataCs = Citys;
      });
    },
    // 楼栋列表
    BuildList: function (val) {
      WeGetBuildList({ rId: val }).then((res) => {
        this.BIdlist = res.data.data;
      });
    },
    // 单元列表
    BuildUnitList: function (val) {
      WeGetBuildUnitList({ bId: val }).then((res) => {
        this.BUIdlist = res.data.data;
      });
    },
    // 楼层列表
    BuildFloorList: function (val) {
      WeGetBuildFloorList({ bId: this.idList.BId, buId: val }).then((res) => {
        this.BFIdlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    // 小区选择器
    onidListRId(val) {
      this.idList.RId = val.RId;
      this.idList.RIdTitle = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 楼栋选择器
    onidListBId(val) {
      this.idList.BId = val.BId;
      this.idList.BIdTitle = val.Title;
      this.idListBId = false;
      this.BuildUnitList(val.BId);
    },
    // 单元选择器
    onidListBUId(val) {
      this.idList.BUId = val.BUId;
      this.idList.BUIdTitle = val.Title;
      this.idListBUId = false;
      this.BuildFloorList(val.BUId);
    },
    // 单元选择器
    onidListBFId(val) {
      this.idList.BFId = val.BFId;
      this.idList.BFIdTitle = val.FloorNo;
      this.idListBFId = false;
      this.BuildRoomList(val.BFId);
    },
    // 房屋选择器
    onidListBRId(val) {
      this.idList.BRId = val.BRId;
      this.idList.BRIdTitle = val.RoomNO;
      this.idListBRId = false;
    },
    // 性别选择器
    onsexshow(val){
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 民族选择器
    onNationshow(val){
      this.datafrom.Nation = val.Coding;
      this.datafrom.NationName = val.Name;
      this.Nationshow = false;
    },
    // 户口状态
    onHouserStatus(val){
      this.datafrom.HouserStatus = val.Coding;
      this.datafrom.HouserStatusName = val.Name;
      this.HouserStatus = false;
    },
    // 户口关系
    onRegisterKind(val){
      this.datafrom.RegisterKindCode = val.Coding;
      this.datafrom.RegisterKindName = val.Name;
      this.RegisterKind = false;
    },
    // 婚姻
    onMarriage(val){
       this.datafrom.MarriageCode = val.Coding;
      this.datafrom.MarriageName = val.Name;
      this.Marriage = false;
    },
    // 学历
    onEducation(val){
      this.datafrom.EducationCode = val.Coding;
      this.datafrom.EducationName = val.Name;
      this.Education = false;
    },
    // 地址
    onLiveCity: function (value) {
      var RegCity = this.addressfrom(value)
      this.datafrom.datafrom = RegCity[0];
      this.datafrom.LiveCityName = RegCity[1];
      this.LiveCity = false;
    },
    addressfrom(value){
      // 地址
      var a,b,c;
      a = value.selectedOptions[0];
      b = value.selectedOptions[1];
      c = value.selectedOptions[2];
      var addressCode = c.value;
      var addressName = a.text + ',' + b.text + ',' + c.text;
      var address = [addressCode,addressName]
      return address
    },
    // 按钮
    dengji() {
      this.addshow = true;
      this.verifyshow = false;
    },

  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>